import React, { useContext } from 'react'
import { context } from '~/layouts'
import { Head } from '~/components'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import * as st from '~/assets/styl/404Sucesso.module.styl'
import img from '~/assets/img/img-404.jpg'
import imgMobile from '~/assets/img/img-404-mobile.jpg'

const Sucesso = () => {
  const { setLoading } = useContext(context)
  setLoading(false)
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' })

  return (
    <>
      <Head />
      <main
        className={st.core}
        style={
          isMobile
            ? { background: `url(${imgMobile}) top center no-repeat` }
            : { background: `url(${img}) top center no-repeat` }
        }
      >
        <section className="container">
          <h1>
            Página não <span className="block">encontrada</span>
          </h1>
          <p className={st.customWidth}>
            A página que você tentou acessar não existe ou está fora do ar
            temporariamente.
          </p>
          <Link to="/">Ir para Home</Link>
          <Link to="/">Conheça nossos seguros</Link>
        </section>
      </main>
    </>
  )
}

export default Sucesso
